import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment, useState, useEffect } from "react"

import { createHtml, wrapSup } from "../../utils/utils"

import HamburgerIcon from "./HamburgerIcon.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

var classNames = require("classnames")
class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  // Generates keyframe style tag to be document.head.appendChild'ed;
  generateKeyframesStyle(width, height, modifier) {
    let radius = 5000
    if (width > height) {
      radius = width
    } else if (width < height) {
      radius = height
    } else {
      radius = width
    }

    // 2 is too low
    if (!modifier) {
      modifier = 2.5
    }

    radius = radius * modifier

    return `
    @keyframes menuAnimOpen {
      0% {
        top: ${-0.5 * radius}px;
        right: ${-0.5 * radius}px;
        width: 0;
        height: 0;
        border-radius: 50%;
      }
      100% {
        top: ${-0.5 * radius}px;
        right: ${-0.5 * radius}px;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
      }
    }
    
    @keyframes menuAnimClose {
      0% {
        top: ${-0.5 * radius}px;
        right: ${-0.5 * radius}px;
        width: ${radius}px;
        height: ${radius}px;
        border-radius: 50%;
      }

      100% {
        top: ${-0.5 * radius}px;
        right: ${-0.5 * radius}px;
        width: 0;
        height: 0;
        border-radius: 50%;
      }
    }`
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
    const menuKeyframes = this.generateKeyframesStyle(
      window.innerWidth,
      window.innerHeight
    )
    const $style = document.createElement("style")
    $style.innerHTML = menuKeyframes
    document.head.appendChild($style)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    let iconColor = "#6d6e6f"
    let iconClass = classNames({
      "hamburger-icon": true,
      open: this.props.isOpen,
    })
    //   if (this.props.atTopOfPage) {
    //   iconColor = "white"
    // }

    return (
      <div className="hamburger-menu-container">
        <Sidebar
          toggleMenu={this.props.toggleMenu}
          isOpen={this.props.isOpen}
          isClosing={this.props.isClosing}
          isOpening={this.props.isOpening}
          key="sidebar1"
          language={this.props.language}
          openMenuLeft={this.props.openMenuLeft}
          openMenuRight={this.props.openMenuRight}
          popularPages={this.props.popularPages}
        />
        <HamburgerIcon
          toggleMenu={this.props.toggleMenu}
          className={iconClass}
          color={iconColor}
          isOpen={this.props.isOpen}
          isClosing={this.props.isClosing}
          isOpening={this.props.isOpening}
          iconType={this.props.iconType}
          boldMenu={this.props.boldMenu}
          language={this.props.language}
        />
      </div>
    )
  }
}
HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

const Sidebar = props => {
  
  const [activeMenu, setActiveMenu] = useState('');
  const [activeLinks, setActiveLinks] = useState([]);

  useEffect(() => {
    if(!props.isOpen) {
      setActiveMenu('')
      setActiveLinks([])
    }
  }, [props.isOpen])

  const handleSubMenu = (menuName, menuLinks) => {

      if(activeMenu === menuName) {
        setActiveMenu('')
        setActiveLinks([])
      } else {
        setActiveMenu(menuName)
        setActiveLinks(menuLinks)
      }
  };

  var sidebarClass = classNames({
    "hamburger-menu": true,
    columns: true,
    "is-vcentered": false,
    "hamburger-menu--closed": !props.isOpen,
    "hamburger-menu--open": props.isOpen,
    "hamburger-menu--closing": props.isClosing,
  })

  var menuContentClass = classNames({
    "hamburger-menu-content columns is-centered": true,
    shown: props.isOpen && !props.isOpening && !props.isClosing,
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    document.getElementById("paymentForm").submit();
  }

  return (
    <div className={sidebarClass}>
      <div className="column is-fullwidth">
        <div className={menuContentClass}>
          <ol className="column menu-list-1">
            {props.openMenuLeft.map((link, i) => (
                <Fragment key={link.url + i}>
                  {link.targetType === "dropdown" &&
                    <>
                      <li className={link.targetType === "dropdown" ? "" : ""}>
                              <a 
                                className='sub-menu-toggle'
                                onClick={() => handleSubMenu(link.title, link.subMenu)}
                                title={link.description}
                              >
                                <span className="menu-count">{"0" + (1 + i)}</span>
                                {link.title}
                                <FontAwesomeIcon  
                                  icon={faChevronRight}  
                                  className={`sub-menu-chevron ${link.title === activeMenu ? "chevron-active" : ""}`} 
                                />
                              </a>
                          </li>

                        <div className={`has-submenu mobile-submenu ${link.title === activeMenu ? "sm-active" : ""}`}>
                          <ActiveListMenu 
                            language={props.language}
                            toggleMenu={props.toggleMenu}
                            menu={link.subMenu}
                          />
                        </div>
                      </>
                    } 

                    {link.targetType === "internal" &&
                      <li key={link.url}>
                          <Link
                              onClick={props.toggleMenu}
                              to={link.url}
                              title={link.description}
                          >
                              <span className="menu-count">{"0" + (1 + i)}</span>
                              {link.title}
                          </Link>
                      </li>
                    }

                    {link.targetType === "external" &&
                      <li key={link.url}>
                          <a
                              onClick={props.toggleMenu}
                              href={link.url}
                              target="_blank"
                              title={link.description}
                          >
                              <span className="menu-count">{"0" + (1 + i)}</span>
                              {link.title}
                          </a>
                      </li>
                    }

                </Fragment>
              ))}

          </ol>
          <div className="column right-column">
            <ul className="menu-list-2">
              <ActiveListMenu 
                language={props.language}
                toggleMenu={props.toggleMenu}
                menu={activeLinks}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
}

const ActiveListMenu = ({ menu, language, toggleMenu }) => {

  return (
    <>
    {menu.map((item, i) => (
      <Fragment key={i}>
        <li key={item.url} className={item.targetType === "dropdown" ? "has-submenu" : ""}>
          {item.targetType === "internal" && (
            <Link
              onClick={toggleMenu}
              to={item.url}
              title={item.description}
            >
              {item.title && item.title.includes("®")
                ? <span dangerouslySetInnerHTML={{__html: wrapSup(item.title)}}></span>
                : item.title 
              }
            </Link>
          )}
          {item.targetType === "external" && (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              title={item.description}
            >
              {item.title}
            </a>
          )}
          {item.targetType === "dropdown" && (
            <DropdownItem 
              language={language}
              toggleMenu={toggleMenu}
              link={item}
            />
          )}
        </li>
      </Fragment>
    ))}
    </>
  )
}

const DropdownItem = ({ link, language, toggleMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const chevronClasses = classNames({
    "silo-chevron": true,
    "chevron-active": isOpen
  });

  const siloLinkClasses = classNames({
    "sub-menu": true,
    "silo-links-hidden": !isOpen
  });

  return (
    <>
      <a
        className={isOpen ? "silo-btn silo-open" : "silo-btn"}
        onClick={() => setIsOpen(!isOpen)}
      >
        {link.title}
        <FontAwesomeIcon  
            icon={faChevronDown}  
            className={chevronClasses} 
        />
      </a>

      {link.subMenu2 && link.subMenu2.map((subLink, i) => (
          <Link
            key={i}
            className={siloLinkClasses}
            onClick={toggleMenu}
            title={subLink.description}
            to={subLink.url}
          >
            {subLink.title}
          </Link>
      ))}
    </>
  )
};

export default HamburgerMenu
