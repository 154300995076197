import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

var classNames = require("classnames")

import "./CopyToClipboard.scss"

function CopyToClipboard({ email, language }) {
  const displayText = language === "es" ? "Correo Electrónico" : "Email Us"
  const tooltipTextBefore =
    language === "es" ? "Copiar al portapapeles" : "Copy to clipboard"
  const toolTipTextAfter =
    language === "es" ? "Copiado al portapapeles" : "Copied to clipboard!"

  const [tooltipText, setTooltipText] = useState(tooltipTextBefore)

  function copyClick(e) {
    e.preventDefault()
    let text = email
    navigator.clipboard.writeText(text).then(
      function () {
        /* clipboard successfully set */
        setTooltipText(toolTipTextAfter)
      },
      function () {
        /* clipboard write failed */
      }
    )
  }
  function outFunc() {
    setTooltipText(tooltipTextBefore)
  }
  return (
    <div className="tooltip" onMouseOut={outFunc}>
      <span className="tooltiptext" id="myTooltip">
        {tooltipText}
      </span>
      <a
        role="button"
        onClick={event => {
          copyClick(event)
        }}
      >
        {displayText}
      </a>
    </div>
  )
}

export default CopyToClipboard
